import './login_page.css';

import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAppState } from '../../state';
import Loading from '../Loading/Loading';
import ErrorModal from '../ErrorModal/ErrorModal';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
;
export default function LoginPage() {
  const { URLRoomName } = useParams();
  const { user, getToken, setToken, setUserName, setExpiresAt, setSid, fetchRoom, isFetching } = useAppState();

  const [roomName, setRoomName] = useState<string>('');
  const [name, setName] = useState<string>(user?.displayName || '');
  const [passcode, setPasscode] = useState<string>('');
  const [errorName, setErrorName] = useState<string>('');
  const [errorPasscode, setErrorPasscode] = useState<string>('');
  const [errorCode, setErrorCode] = useState<number>(-1);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showPage, setShowPage] = useState<boolean>(false);
  const [usePasscode, setUsePasscode] = useState<boolean>(true);

  useEffect(() => {
    if (roomName === '') return
    const fetchData = async () => {
      await fetchRoom(roomName).then((res)=> {
        setShowPage(true);
        if (res.use_passcode === false) setUsePasscode(false)
      }).catch(() => {
        setErrorMessage('404');
      });
    };
    fetchData();
    // 副作用のないfetchRoomがuseEffect依存性チェックに引っかかる問題(https://github.com/facebook/react/issues/15865)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomName]);

  useEffect(() => {
    setRoomName(URLRoomName);
  }, [URLRoomName]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handlePasscodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasscode(event.target.value);
  };

  const handleValidation = (params: { name: [string], passcode: [string], expires_at: [string], member: [string] }) => {
    if (params.name) {
      if (params.name[0] === 'required') {
        setErrorName('氏名が入力されていません｡');
      } else if (params.name[0] === 'too-long:255') {
        setErrorName('255文字以内で入力してください。');
      }
    }
    if (params.passcode) {
      setErrorPasscode('入力されたパスコードが異なります。');
    }
    if (params.expires_at) {
      if (params.expires_at[0] === 'expired') {
        // Room期限過ぎた場合
        setErrorMessage('expired');
      } else if (params.expires_at[0] === 'no-duration') {
        // Roomで設定された利用時間を過ぎた場合
        setErrorMessage('expired');
      }
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorName('');
    setErrorPasscode('');
    setErrorCode(-1);
    setErrorMessage('');
    await getToken(name, roomName, passcode)
      .then(res => {
        if (res.access_token) {
          setUserName(name);
          setToken(res.access_token);
          if (res.sid) setSid(res.sid);
          if (res.expires_at) setExpiresAt(res.expires_at);
        } else if (res.params) {
          handleValidation(res.params)
        }
      })
      .catch(err => {
        setErrorCode(err.status)
        setErrorMessage(err.statusText)
      });
  };

  return (
    <div className="modal">
      {(() => {
        if (isFetching) {
          return <Loading />
        } else if (errorMessage === '404') {
          return <NotFoundPage />;
        } else if (errorCode !== -1 || errorMessage !== '') {
          return <ErrorModal errorCode={errorCode} errorMessage={errorMessage} />
        } else if (showPage) {
          return (
            <form className="modal-inner" onSubmit={handleSubmit}>
              <dl className="input-dl">
                <dt>氏名</dt>
                <dd>
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    className={errorName ? 'is-error' : ''}
                    placeholder="氏名を入力してください"
                  />
                  {errorName ? <p className="error-text">{errorName}</p> : ''}
                </dd>
              </dl>
              {usePasscode ? (
                  <dl className="input-dl">
                    <dt>パスコード</dt>
                    <dd>
                      <input
                        type="password"
                        value={passcode}
                        onChange={handlePasscodeChange}
                        className={errorPasscode ? 'is-error' : ''}
                        placeholder="パスコードを入力してください"
                      />
                      {errorPasscode ? <p className="error-text">{errorPasscode}</p> : ''}
                    </dd>
                  </dl>
                ) : null
              }
              <button
                type="submit"
                disabled={(!usePasscode && (!name || !roomName || isFetching)) || (usePasscode && (!name || !roomName || isFetching || !passcode))}
                className="button"
              >
                入室
              </button>
            </form>
          );
        }
      })()}
    </div>
  );
}
