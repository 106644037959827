import './menubar.css';

import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';

import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Controls/EndCallButton/EndCallButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import Loading from '../Loading/Loading';

const useStyles = makeStyles(() =>
  createStyles({
    rightButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isFetching, userName } = useAppState();
  const { isConnecting } = useVideoContext();
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const roomState = useRoomState();

  useEffect(() => {
    const ua: string = navigator.userAgent.toLowerCase()
    const isMobileOrTablet = ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0
    setIsMobileOrTablet(isMobileOrTablet)
  }, []);

  return (
    <div>
      <div className="menubar">
        <div className={classes.rightButtonContainer}>
          <DeviceSelector />
          {isMobileOrTablet ? '' : <ToggleFullscreenButton />}
          <EndCallButton noTooltip={true} />
        </div>
      </div>
      {roomState === 'disconnected' && <p className="userName">{userName}</p>}
      {(isConnecting || isFetching) && <Loading />}
    </div>
  );
}
