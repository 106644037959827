import './loading.css';

import React from 'react';

export default function Loading() {
  return (
    <div className="modal-wrap">
      <div className="modal-overlay">
        <div className="loader">Loading...</div>
      </div>
    </div>
  );
}
