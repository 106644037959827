import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function EndCallButton(props: { noTooltip?: boolean }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const roomState = useRoomState();

  return (
    <>
      {
        roomState === 'disconnected' ? (
          <Fab className={`${classes.fab} button-call_end`} disabled={true}>
            <CallEnd />
          </Fab>
        ) : props.noTooltip ? (
          <Fab onClick={() => room.disconnect()} className={`${classes.fab} button-call_end`}>
            <CallEnd />
          </Fab>
        ) : (
          <Tooltip title={'通話終了'} onClick={() => room.disconnect()} placement="top" PopperProps={{ disablePortal: true }}>
            <Fab className={`${classes.fab} button-call_end`}>
              <CallEnd />
            </Fab>
          </Tooltip>

        )
      }
    </>
  );
}
