import React from 'react';
import './style.css';

export default function NotFoundPage() {
  return (
    <div className="l-error">
      <div className="l-error-inner">
        <h1 className="error_title">404</h1>
        <h2 className="error_sub_title">Page Not Found</h2>
        <p className="error_japanese">ページが見つかりません</p>
        <p className="error_text">アクセスしようとしたページが<br />削除・変更されたか<br />現在利用できない可能性があります。</p>
      </div>
    </div>
  );
}
