import './error_modal.css';
import { ReactComponent as WarningIcon } from './warning.svg';
import React from 'react';

export default function ErrorModal(props: { errorCode?: number, errorMessage?: string}) {
  return (
    <div className="error-modal">
      {(() => {
        if (props.errorMessage === 'expired') {
          return (
            <div className="error_modal-inner">
              <WarningIcon />
              <h2>URLが期限切れです</h2>
              <p>
                アクセスしようとしたページの
                <br />
                有効期限が切れています。
              </p>
              <p>
                お手数ですが､本ルームの招待元に連絡し､
                <br />
                有効なURLの再発行を依頼してください。
              </p>
            </div>
          )
        } else if (props.errorMessage === 'noVacancy') {
          return (
            <div className="error_modal-inner">
              <WarningIcon />
              <h2>ルームが満室です</h2>
              <p>
                アクセスしようとしたルームは
                <br />
                現在定員に達しています｡
              </p>
            </div>
          )
        } else {
          return (
            <div className="error_modal-inner">
              <WarningIcon />
              <h2>サーバーエラーです</h2>
              <p></p>
              <p>
                エラーコード: {props.errorCode}
                <br />
                {props.errorMessage}
              </p>
            </div>
          )
        }
      })()}
    </div>
  );
}
