import moment from 'moment'

import { Callback } from '../../../types';
import EventEmitter from 'events';
import { isMobile } from '../../../utils';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppState } from '../../../state';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback, options?: ConnectOptions) {
  const { URLRoomName } = useParams();
  const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
  const [isConnecting, setIsConnecting] = useState(false);
  const localTracksRef = useRef<LocalTrack[]>([]);
  const { finishRoom, expiresAt, sid } = useAppState();
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
    // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
    // once the user is connected to the room.
    localTracksRef.current = localTracks;
    setRoomName(URLRoomName);
  }, [localTracks, URLRoomName]);

  const connect = useCallback(
    token => {
      setIsConnecting(true);
      return Video.connect(token, { ...options, tracks: [] }).then(
        newRoom => {
          setRoom(newRoom);
          const disconnect = () => {
            // Roomを閉じる処理
            if (expiresAt) {
              // expiresAt が存在する場合、入室中にブラウザが閉じられたらsendBeaconでfinish APIを叩く
              const blob = new Blob([JSON.stringify({ sid })], { type: 'application/json' });
              navigator.sendBeacon(`/api/video_chat_rooms/${roomName}/finish`, blob);
            }
            // Roomを閉じる
            newRoom.disconnect();
          }

          // expiresAt が存在する場合、expiresAtの時間になったら強制リロード
          let timeoutId: number = 0
          if (expiresAt) {
            const diffMilliSec = moment(expiresAt).diff(moment(), 'seconds') * 1000
            if (diffMilliSec > 0) {
              // setTimeoutで時間になったらreloadさせる
              timeoutId = window.setTimeout(() => { window.location.reload(); }, diffMilliSec)
            } else {
              // 0以下の場合は既に時刻を過ぎているので即reload
              window.location.reload();
            }
          }

          newRoom.once('disconnected', async () => {
            // Roomを閉じる前処理
            // expiresAt が存在する場合、reloadタイマーを解除してfinish APIを叩く
            if (expiresAt) {
              window.clearTimeout(timeoutId)
              await finishRoom(roomName, sid)
            }

            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(new EventEmitter() as Room));
            window.removeEventListener('beforeunload', disconnect);
            if (isMobile) window.removeEventListener('pagehide', disconnect);
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          localTracksRef.current.forEach(track =>
            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
            // However, tracks must be published manually in order to set the priority on them.
            // All video tracks are published with 'low' priority. This works because the video
            // track that is displayed in the 'MainParticipant' component will have it's priority　set to 'high' via track.setPriority()
            newRoom.localParticipant.publishTrack(track, { priority: track.kind === 'video' ? 'low' : 'standard' })
          );

          setIsConnecting(false);
          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);
          // Add a listener to disconnect from the room when a mobile user closes their browser
          if (isMobile) window.addEventListener('pagehide', disconnect);
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [options, onError, finishRoom, roomName, expiresAt, sid]
  );

  return { room, isConnecting, connect };
}
