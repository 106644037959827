import React, { createContext, useContext, useState } from 'react';
import { TwilioError } from 'twilio-video';

interface GetTokenApiHandlingType {
  access_token ?: string,
  expires_at?: string,
  sid?: string,
  status ?: number,
  statusText ?: string,
  // バリデーションエラー用のレスポンス
  params?: { name: [string], passcode: [string], expires_at: [string], member: [string] },
}

interface FetchRoomApiHandlingType {
  use_passcode?: boolean,
  status?: number,
  statusText?: string,
}

interface FinishRoomApiHandlingType {
  status?: number,
  statusText?: string,
}

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode: string): Promise<GetTokenApiHandlingType>;
  fetchRoom(roomName: string): Promise<FetchRoomApiHandlingType>;
  finishRoom(roomName: string, sid: string): Promise<FinishRoomApiHandlingType>;
  user?: null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  token: string;
  setToken(token: string): void;
  userName: string;
  setUserName(name: string): void;
  expiresAt: string;
  setExpiresAt(expiresAt: string): void;
  sid: string;
  setSid(sid: string): void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useState('default');

  const [token, setToken] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [expiresAt, setExpiresAt] = useState<string>('');
  const [sid, setSid] = useState<string>('');

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    token,
    setToken,
    userName,
    expiresAt,
    sid,
    setUserName,
    setExpiresAt,
    setSid,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    getToken: async (identity, roomName, passcode) => {
      return new Promise((resolve, reject) => {
        const headers = { 'Content-Type': 'application/json; charset=utf-8' };
        const endpoint = `/api/video_chat_rooms/${roomName}/join`;
        const req = { name: identity, passcode };
        return fetch(endpoint, { method: 'POST', headers, body: JSON.stringify(req) })
          .then(res => {
            if (res.status === 422) {
              // バリデーションエラーの場合
              return res
                .json()
                .then(json => resolve(json))
                .catch(err => reject(err));
            } else if (res.ok) {
              return res
                .json()
                .then(json => resolve(json))
                .catch(err => reject(err));
            } else {
              reject(res);
            }
          })
          .catch(err => reject(err));
        });
    },
    fetchRoom: async roomName => {
      return new Promise((resolve, reject) => {
        const headers = { 'Content-Type': 'application/json; charset=utf-8' };
        const endpoint = `/api/video_chat_rooms/${roomName}`;
        return fetch(endpoint, { method: 'GET', headers })
          .then(res => {
            if (res.ok) {
              return res
                .json()
                .then(json => resolve(json))
                .catch(err => reject(err));
            } else {
              reject(res);
            }
          })
          .catch(err => reject(err));
      });
    },
    finishRoom: async (roomName, _sid) => {
      return new Promise((resolve, reject) => {
        const headers = { 'Content-Type': 'application/json; charset=utf-8' };
        const endpoint = `/api/video_chat_rooms/${roomName}/finish`;
        const req = { sid: _sid };
        return fetch(endpoint, { method: 'POST', headers, body: JSON.stringify(req)})
          .then(res => {
            if (res.ok) {
              return resolve({});
            } else {
              reject(res);
            }
          })
          .catch(err => reject(err));
      });
    },
  };

  const getToken: StateContextType['getToken'] = (name, room, passcode) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room, passcode)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const fetchRoom: StateContextType['fetchRoom'] = roomName => {
    setIsFetching(true);
    return contextValue
      .fetchRoom(roomName)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const finishRoom: StateContextType['finishRoom'] = (roomName, _sid) => {
    setIsFetching(true);
    return contextValue
      .finishRoom(roomName, _sid)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };
  return <StateContext.Provider value={{ ...contextValue, getToken, fetchRoom, finishRoom }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
