import './local_video_preview.css';

import React, { FormEvent, useState, useEffect } from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';

export default function LocalVideoPreview() {
  const { isFetching, token } = useAppState();
  const { localTracks, isConnecting, connect } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;
  const roomState = useRoomState();
  const [enabledAudioDevices, setEnabledAudioDevices] = useState(false);

  useEffect(() => {
    const getAudioDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices().then(devices => devices);
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      if (audioDevices.length === 0 || !audioDevices[0].deviceId) {
        setEnabledAudioDevices(false);
        alert('選択できる音声入力がないか、マイクへのアクセス権限が許可されていません。');
      } else {
        setEnabledAudioDevices(true);
      }
    };
    getAudioDevices();
  }, []);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    connect(token);
  };

  return (
    <>
      { videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null }
      <div className="local_video_wrapper">
        {roomState === 'disconnected' && (
          <form className="join-form" onSubmit={handleSubmit}>
            <p className="connect_caption">開始前にマイクとカメラの設定、ボリュームをご確認ください。</p>
            <button className="button-join" type="submit" disabled={isConnecting || isFetching || !enabledAudioDevices}>
              通話開始
            </button>
          </form>
        )}
      </div>
    </>
  );
}
