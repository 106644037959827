import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import ScreenShare from '@material-ui/icons/ScreenShare';
import StopScreenShare from '@material-ui/icons/StopScreenShare';
import Tooltip from '@material-ui/core/Tooltip';

import useScreenShareToggle from '../../../hooks/useScreenShareToggle/useScreenShareToggle';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = '画面共有';
export const STOP_SCREEN_SHARE_TEXT = '画面共有停止';
export const SHARE_IN_PROGRESS_TEXT = '他のユーザーが画面共有しているため画面共有できません';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
      '&[disabled]': {
        color: 'rgba(225, 225, 225, 0.8)',
        backgroundColor: 'rgba(175, 175, 175, 0.6);',
      },
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
  const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = SCREEN_SHARE_TEXT;
  if (isScreenShared) tooltipMessage = STOP_SCREEN_SHARE_TEXT;
  if (disableScreenShareButton) tooltipMessage = SHARE_IN_PROGRESS_TEXT;

  useEffect(() => {
    const ua: string = navigator.userAgent.toLowerCase()
    const isMobileOrTablet = ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0
    setIsMobileOrTablet(isMobileOrTablet)
  }, []);

  return (
    <>
      {!isMobileOrTablet ? (
        <Tooltip
          title={tooltipMessage}
          placement="top"
          PopperProps={{ disablePortal: true }}
          style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
        >
          <div>
            {/* The div element is needed because a disabled button will not emit hover events and we want to display
              a tooltip when screen sharing is disabled */}
            <Fab className={classes.fab} onClick={toggleScreenShare} disabled={isDisabled}>
              {isScreenShared ? <StopScreenShare /> : <ScreenShare />}
            </Fab>
          </div>
        </Tooltip>
      ) : '' }
    </>
  );
}
